<template>
  <div class="filters">
    <!-- global: {{ global }} -->
    <div v-if="sensorsOptions" class="header-options__container content-container">
      <div class="header-options__filters">
        <div v-if="filters.includes(FILTERS_ENUM.TECHNOLOGY)" class="header-options__filters__filter">
          <div class="header-options__filters__filter__title">TECHNOLOGY {{ getLabelTopFilter('technology') }}</div>
          <multi-select
            v-if="technologyOptions"
            v-model="internalValue.technology"
            :options="technologyOptions"
            :multiple="true"
            :select-all="true"
          />
        </div>
        <div v-if="filters.includes(FILTERS_ENUM.SENSORS) && !isSimplified" class="header-options__filters__filter">
          <div class="header-options__filters__filter__title">SENSORS {{ getLabelTopFilter('sensor') }}</div>
          <multi-select
            v-if="sensorsOptions"
            v-model="internalValue.sensor"
            :options="sensorsOptions"
            :multiple="true"
            :select-all="canSelectAllSensors"
          />
        </div>
        <div v-if="filters.includes(FILTERS_ENUM.COUNTRIES)" class="header-options__filters__filter">
          <div class="header-options__filters__filter__title">COUNTRIES {{ getLabelTopFilter('country') }}</div>
          <multi-select
            v-model="internalValue.country"
            :options="countriesOptions || []"
            :multiple="true"
            :loading="$asyncComputed.availableCountries.updating"
            :select-all="true"
          />
        </div>
        <div v-if="filters.includes(FILTERS_ENUM.GENDERS)" class="header-options__filters__filter">
          <div class="header-options__filters__filter__title">GENDERS {{ getLabelTopFilter('gender') }}</div>
          <multi-select
            v-if="gendersOptions"
            v-model="internalValue.gender"
            :options="gendersOptions"
            :multiple="true"
            :select-all="false"
          />
        </div>
        <div v-if="filteredSensors && filteredSensors.length > 0 && filters.includes(FILTERS_ENUM.DATE_RANGE)" class="header-options__filters__filter">
          <div class="header-options__filters__filter__title">DATE RANGE</div>
          <DateRangePicker v-model="internalDateRange" :minmax-range="dateRangeLimits" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSensors } from '@/services/api'
import store from '../store'
import { isEqual } from 'lodash'

import MultiSelect from './MultiSelect.vue'

import { getCountries, getFunnelNodeTrees } from '../services/api'
import DateRangePicker from './DateRangePicker.vue'
import { getSettingByRoute, FILTERS_ENUM, FILTER_SETTING_ID } from '../settings/filterApplySettings'
import { getShortRetailer, getShortTechnology, removeNames } from '../settings/shortKeys'
import { defaultTopFilters } from '../store/modules/product-data'
import { ROUTE_NAMES } from '../settings/routerSettings'
import { findIntersection } from '../utils/intersection'

// const HARDCODE_SENSORS = ['SEPHORA', 'SCENTICA', 'PACO_RABANNE']

export default {
  components: { MultiSelect, DateRangePicker },
  model: {
    prop: 'localValue',
    event: 'change',
  },
  props: {
    global: { default: true },
    localValue: { default: null },
  },
  data() {
    return {
      FILTERS_ENUM,
      internalValue: { ...defaultTopFilters },
    }
  },
  asyncComputed: {
    funnelNodeTrees() {
      // task #340: Add condition to fetch funnel tree only when needed.
      if (!this.isFunnelView && !this.isTopChosenView) return [];
      return getFunnelNodeTrees()
    },
    allSensors() {
      return getSensors().then(sensor => {
        // remove with hardcode sensros
        var copy = [...sensor]
        if (this.setting?.id === FILTER_SETTING_ID.INGREDIENTS)
          copy = copy?.filter(sensor => sensor.ingredientInsights)
        return copy
      })
    },
    availableCountries() {
      return getCountries()
    },
  },
  computed: {

    /**
     * task #340: Refactor filters to apply funnel and top chosen view filters only when needed.
     * Add condition to fetch funnel tree only when needed.
     * Improve performance
     */
    filteredSensors() {
      if (!this.allSensors?.length) return [];

      let filtered = this.allSensors;

      if (!this.isFunnelView && !this.isTopChosenView) return filtered;
      
      // Apply funnel filter if in funnel view
      if (this.isFunnelView) {
        const funnelSensorSet = new Set(this.funnelSensorsIds);
        filtered = filtered.filter(sensor => funnelSensorSet.has(sensor.id));
      }
      if (!this.funnelNodeTrees?.length) return [];
      
      // Funnel view and top chosen view additional filtering
      const range = this.getSensorsDateLimits(filtered)
      
      filtered = filtered.filter(s => {
        const tree = this.funnelNodeTrees?.find(tree => tree.sensors.includes(s.id))
        return tree && new Date(tree.from).getTime() <= range.end.getTime()
      })
      
      if (this.isTopChosenView) {
        return filtered.filter(s => {
          const tree = this.funnelNodeTrees?.find(tree => tree.sensors.includes(s.id))
          return tree.allowTopChosenQuery
        })
      }
      return filtered
    },
    setting() {
      return getSettingByRoute(this.$route)
    },
    funnelSensorsIds() {
      return this.funnelNodeTrees ? this.funnelNodeTrees.map(({ sensors }) => sensors).flat() : []
    },
    isFunnelView() {
      return this.$route.name === ROUTE_NAMES.Funnel
    },
    isTopChosenView() {
      return this.$route.name === ROUTE_NAMES.TopChosen
    },
    isSpecialView() {
      return this.isFunnelView || this.isTopChosenView
    },
    unrelatedSensorsDisabled() {
      return this.isSpecialView && this.internalValue.sensor?.length > 0
    },
    filters() {
      return this.setting.filters
    },
    canSelectAllSensors() {
      return (this.isSpecialView && this.internalValue.sensor?.length > 0) || !this.isSpecialView
    },
    internalDateRange: {
      get() {
        return { start: this.internalValue.from, end: this.internalValue.to }
      },
      set(ob) {
        this.internalValue.from = ob.start
        this.internalValue.to = ob.end
      },
    },
    technologyOptions() {
      if (!this.filteredSensors) return
      let technologies = this.filteredSensors.map(({ type }) => type)
      technologies = technologies
        .filter((t, index) => technologies.indexOf(t) === index)
        .filter(e => !!e)
      return [
        ...technologies.map(id => ({
          id,
          value: this.$t(`technology_${id.toLowerCase()}`),
        })),
      ]
    },
    sensorsOptions() {
      return (
        this.filteredSensors && [
          ...this.filteredSensors
            .map(sensor => ({
              id: sensor.id,
              value: `${getShortRetailer(sensor.retailer)} ${getShortTechnology(
                sensor.type
              )} ${removeNames(sensor.retailer, sensor.type, sensor.name)}`,
              _retailer: sensor.retailer,
              $isDisabled: sensor.fake || this.isSensorDisabled(sensor),
            }))
            .sort((a, b) => {
              if (a.value < b.value) {
                return -1
              }
              if (a.value > b.value) {
                return 1
              }
              return 0
            }),
        ]
      )
    },
    countriesOptions: function () {
      return (
        this.availableCountries && [
          ...this.availableCountries
            .map(country => {
              const n = this.numberOfSensorsWithCountry(country)
              return ({
                id: country.id,
                _nSensor: n,
                value: `${country.name}` + (n > 0 ? ` (${n} sensors)` : ''),
              })
            })
            .sort((a, b) => {
              if (a.value > b.value) {
                return 1
              } else {
                return -1
              }
            }),
        ]
      )
    },
    gendersOptions() {
      return [
        {
          id: 'M',
          value: 'Male',
        },
        {
          id: 'F',
          value: 'Female',
        },
        {
          id: 'U',
          value: 'Unisex',
        },
      ]
    },
    ...mapGetters(['topFilters','isSimplified']),
    currentFunnelTree() {
      const sensors = this.internalValue.sensor
      const trees = this.funnelNodeTrees
      return (
        trees &&
        sensors &&
        this.isSpecialView &&
        trees.find(tree => sensors.some(sensor => tree.sensors.includes(sensor)))
      )
    },
    dateRangeLimits() {
      const sensors =
        this.internalValue.sensor?.length > 0
          ? this.filteredSensors.filter(sensor => this.internalValue.sensor.includes(sensor.id))
          : this.filteredSensors
      const range = this.getSensorsDateLimits(sensors)
      const forcedMinDate =
        this.isFunnelView && this.currentFunnelTree && new Date(this.currentFunnelTree.from)
      if (!this.internalValue.sensor) {
        if (forcedMinDate) {
          range.start = forcedMinDate
        }
        return range
      }
      if (forcedMinDate) {
        range.start = forcedMinDate
      }
      return range
    },
  },
  watch: {
    internalValue: {
      deep: true,
      handler(value) {
        // this.$emit('change', { ...value })
        if (value.sensor && !value.sensor[0]) value.sensor = null
        // value = this.validateDates(value)
        if (JSON.stringify(value) !== JSON.stringify(this.internalValue)) this.internalValue = value
        if (this.global) {
          store.dispatch('setTopFilters', { ...value })
        } else {
          this.$emit('change', { ...value })
        }
      },
      //  immediate: true,
    },
    dateRangeLimits: {
      handler(value) {
        if (!value.start || !value.end) return
        const intersection = findIntersection(
          [value.start.getTime(), value.end.getTime()],
          [this.internalDateRange.start.getTime(), this.internalDateRange.end.getTime()],
          [value.start.getTime(), value.end.getTime()]
        )
        this.internalDateRange = {
          start: new Date(intersection[0]),
          end: new Date(intersection[1]),
        }
        // if(value.start && value.start.getTime() > this.internalDateRange.start.getTime())
        //   this.internalDateRange = {...this.internalDateRange,start:value.start}
        // if(value.end && value.end.getTime() < this.internalDateRange.end.getTime())
        //   this.internalDateRange = {...this.internalDateRange, end:value.end}
        // if(value.start && value.start.getTime() > this.internalDateRange.end.getTime()){
        //   let end = new Date(value.start)
        //   // Next day?
        //   // end.setDate(end.getDate()+1)
        //   this.internalDateRange ={...this.internalDateRange, end}
        // }
      },
    },
    topFilters: {
      immediate: true,
      handler(value) {
        if (this.global) {
          if (JSON.stringify(value) !== JSON.stringify(this.internalValue)) {
            this.internalValue = { ...value }
          }
        }
      },
    },
    sensorsOptions(val, prev) {
      if (isEqual(val, prev) || !val) return
      this.internalValue.sensor = this.internalValue.sensor?.filter(s =>
        val.map(v => v.id).includes(s)
      )
    },
    localValue: {
      deep: true,
      immediate: true,
      handler(value) {
        // if (this.global) {
        //   this.internalValue = { ...this.internalValue, ...this.topFilters }
        // } else {
        //   this.internalValue = { ...value }
        // }
        if (!this.global) {
          if (JSON.stringify(value) !== JSON.stringify(this.internalValue)) {
            this.internalValue = { ...value }
          }
        }
      },
    },
  },
  // mounted() {
  //   this.internalValue = { ...this.internalValue, ...this.topFilters }
  // },
  methods: {
    getLabelTopFilter(field) {
      const n = this.internalValue[field]?.length
      const label = n && n !== 0 ? n : this.$t('filter_all')
      return `(${label})`
    },
    getSensorsDateLimits(sensors) {
      return sensors?.reduce(
        (range, sensor) => {
          if (range.start.getTime() > sensor.from.getTime()) range.start = sensor.from
          if (range.end.getTime() < sensor.to.getTime()) range.end = sensor.to
          return range
        },
        { start: sensors[0]?.from, end: sensors[0]?.to }
      )
    },
    isSensorDisabled(sensor) {
      return (
        this.unrelatedSensorsDisabled &&
        this.currentFunnelTree?.sensors?.every(id => id !== sensor.id)
      )
    },
    numberOfSensorsWithCountry(country) {
      return this.filteredSensors?.filter(s => s.countries?.includes(country.id)).length
    },
  },
}
</script>

<style lang="stylus" scoped>
.header__right
  display: flex
  align-items: center
  margin-left: auto

.header__title
  m-font-size(32, 44)
  color: #333
  cursor: pointer

.header__logout
  m-font("Lelo", "light")
  m-font-size(12, 14)
  margin-left: vw(10px)
  background-color: #fff
  color: #4F4F4F !important
  text-transform: uppercase

  &:hover
    color: #fff !important

// .header__logo
// position: relative
// margin-left: vw(40px)
// width: vw(120px)
// height: vw(20px)
// background-image: url("~@/assets/logo-puig.svg")
// background-position: center center
// background-size: contain
// background-repeat: no-repeat

// &:before
// position: absolute
// top: 0
// left: vw(-20px)
// width: 1px
// height: 100%
// background-color: #828282
// content: ""
.header-options,
.header-options-top
  display: flex
  justify-content: center
  padding-right: vw(20px)
  padding-left: vw(20px)
  min-height: $header-height
  width: 100%
  border-bottom: 1px solid $concrete
  background-color: #fff

  &.header-brand
    justify-content: space-between

.header-options-top
  align-items: center

.header-options
  padding: vw(10px) vw(20px)

.header-options__title-container
  margin-top: 0.1em

  .nav-icon
    margin-right: 20px

.user-name-container
  m-font-size(18, 22)
  display: flex
  display: none
  justify-content: center
  align-items: center
  margin-right: vw(20px)
  margin-left: auto
  padding-top: 0.2em
  color: #000
  font-weight: 500

.header-options__title
  m-font-size(22, 30)

// .header-options__container
// display: flex
// width: 100%
// height: 100%
// gap: vw(40px)
.header-options__filters
  display: flex
  flex: 1 1 0%
  // flex-wrap: wrap
  justify-content: flex-end
  gap: vw(20px)

.header-options__filters__filter
  display: flex
  align-items: center
  gap: vw(10px)

.header-options__filters__filter__title
  m-font-size(12, 16)
  font-family: "Lelo"

.multiselect-container,
.multiselect,
.date-range-picker
  min-height: $multiselect-height
  width: $multiselect-width
  height: $multiselect-height
  font-family: "Lelo"
</style>