<template>
  <div class="header">
    <div class="header-options-top header-brand">
      <div class="header__title" @click="changeRoute('/')" />
      <div class="header__right">
        <div class="user-name-container">CT</div>
        <a v-if="user" class="header__logout btn btn--primary" @click="logout">{{ $t('header_logout') }}</a>
        <!-- <div class="header__logo" /> -->
      </div>
    </div>
    <div v-if="currentpage !== '/'" class="header-options" :class="{'no-height': this.$route.query.compare}">
      <div class="header-options__title-container">
        <div class="header-options__title">{{ $t(setting.name) }}</div>
      </div>
      <Filters v-if="showFilters" />
      <div v-if="actions" class="header-actions">
        <div
          v-if="actions.includes(ACTIONS_ENUM.COMPARE)"
          class="compare-btn btn"
          :class="$route.query.compare ? 'btn--primary': 'btn--secondary'"
          @click="toggleCompare"
        >
          COMPARE
        </div>
        <div
          v-if=" actions.includes(ACTIONS_ENUM.DEEP_ANALYSIS)"
          class="compare-btn btn"
          :class="$route.query.deep ? 'btn--primary': 'btn--secondary'"
          @click="toggleDeepAnalyisis"
        >
          DEEP ANALYISIS
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { mapGetters, mapActions } from 'vuex'
import { logout } from '@/services/api'
import Filters from './Filters.vue'
import { getSettingByRoute, FILTERS_ENUM, ACTIONS_ENUM } from '../settings/filterApplySettings'
import { ROUTE_NAMES } from '../settings/routerSettings'

export default {
  components: { Filters },
  data() {
    return {
      FILTERS_ENUM,
      ACTIONS_ENUM,
      internalValue: {},
    }
  },
  asyncComputed: {
    // availableSensors() {
    //   return getSensors().then(sensor => {
    //     // remove with hardcode sensros
    //     var copy = [...sensor]
    //     if (this.setting.id === FILTER_SETTING_ID.INGREDIENTS)
    //       copy = copy?.filter(sensor => sensor.ingredientInsights)
    //     return copy
    //   })
    // },
    // availableCountries() {
    //   return getCountries()
    // },
  },
  computed: {
    setting() {
      return getSettingByRoute(this.$route)
    },
    showFilters() {
      return (
        !this.$route.query.compare &&
        ![ROUTE_NAMES.Funnel, ROUTE_NAMES.TopChosen].includes(this.$route.name)
      )
    },
    user() {
      return this.$store.state.user?.data
    },
    filters() {
      return this.setting.filters
    },
    actions() {
      return this.setting.actions
    },

    currentpage() {
      return this.$route.path
    },

    // minmaxRange() {
    //   let { from, to } = this.availableSensors.reduce((range, sensor) => {
    //     if (range.from > sensor.from) range.from = sensor.from
    //     if (range.to < sensor.to) range.to = sensor.to
    //     return range
    //   })
    //   return { start: from, end: to }
    // },
    // currentMinmaxRange() {
    //   // if (!this.internalValue.sensor) return this.minmaxRange
    //   // let sensor = this.availableSensors.find(s => s.id === this.internalValue.sensor[0])
    //   // let range = { ...this.minmaxRange }
    //   // if (range.start < sensor.from) range.start = sensor.from
    //   // if (range.end > sensor.to) range.end = sensor.to
    //   // return range

    //   if (!this.internalValue.sensor) return this.minmaxRange
    //   let sensors = this.availableSensors.filter(s => this.internalValue.sensor.indexOf(s.id) >= 0)
    //   let range = sensors.reduce(
    //     (range, sensor) => {
    //       if (range.from > sensor.from) range.from = sensor.from
    //       if (range.to < sensor.to) range.to = sensor.to
    //       return range
    //     },
    //     { start: sensors[0].from, end: sensors[0].to }
    //   )
    //   return range
    // },
  },
  watch: {
    // internalValue: {
    //   deep: true,
    //   handler(value) {
    //     // this.$emit('change', { ...value })
    //     if (value.sensor && !value.sensor[0]) value.sensor = null
    //     // value = this.validateDates(value)
    //     if (JSON.stringify(value) !== JSON.stringify(this.internalValue)) this.internalValue = value
    //     console.log('internal value change', value)
    //     store.dispatch('setTopFilters', { ...value })
    //   },
    //   immediate: true,
    // },
  },
  mounted() {
    //console.log('mounted header')
    //this.internalValue = { ...this.internalValue, ...this.topFilters }
  },

  methods: {
    logout() {
      logout()
        .then(() => {
          this.$store.dispatch('logout')
          this.$router.push({ name: ROUTE_NAMES.Login })
        })
        .catch(err => () => console.err('there was an error during logout', err))
    },

    changeRoute(route) {
      this.$router.history.current.path !== route && this.$router.push(route)
    },
    toggleCompare() {
      let query = { ...this.$route.query }
      if (query.compare) delete query.compare
      else query.compare = true
      this.$router.push({ query })
    },
    toggleDeepAnalyisis() {
      let query = { ...this.$route.query }
      if (query.deep) delete query.deep
      else query.deep = true
      this.$router.push({ query })
    },
  },
}
</script>

<style lang="stylus" scoped>
.header__right
  display: flex
  align-items: center
  margin-left: auto

.header__title
  width: vw(280px)
  height: vw(70px)
  background-image: url("~@/assets/logo-scent-xp.png")
  background-position: left center
  background-size: contain
  background-repeat: no-repeat
  cursor: pointer

.header__logout
  m-font("Lelo", "light")
  m-font-size(12, 14)
  margin-left: vw(10px)
  background-color: #fff
  color: #4F4F4F !important
  text-transform: uppercase

  &:hover
    color: #fff !important

// .header__logo
// position: relative
// margin-left: vw(40px)
// width: vw(120px)
// height: vw(20px)
// background-image: url("~@/assets/logo-puig.svg")
// background-position: center center
// background-size: contain
// background-repeat: no-repeat

// &:before
// position: absolute
// top: 0
// left: vw(-20px)
// width: 1px
// height: 100%
// background-color: #828282
// content: ""
.header-options,
.header-options-top
  display: flex
  justify-content: space-between
  align-items: center
  padding-right: vw(20px)
  padding-left: vw(20px)
  min-height: $header-height
  width: 100%
  border-bottom: 1px solid $concrete
  background-color: #fff
  gap: vw(20px)

  &.header-brand
    justify-content: space-between

.header-options
  min-height: 0

  &.no-height
    min-height: 0

.header-actions
  display: flex

.header-options-top
  align-items: center

.header-options
  padding: vw(10px) vw(20px)

.header-options__title-container
  margin-top: 0.1em

  .nav-icon
    margin-right: 20px

.user-name-container
  m-font-size(18, 22)
  display: flex
  display: none
  justify-content: center
  align-items: center
  margin-right: vw(20px)
  margin-left: auto
  padding-top: 0.2em
  color: #000
  font-weight: 500

.header-options__title
  m-font-size(22, 30)

.header-options__container
  display: flex
  width: 100%
  height: 100%
  gap: vw(40px)

.header-options__filters
  display: flex
  flex: 1 1 0%
  flex-wrap: wrap
  justify-content: flex-end
  gap: vw(20px)

.header-options__filters__filter
  display: flex
  align-items: center
  gap: vw(10px)

.header-options__filters__filter__title
  m-font-size(12, 16)
  font-family: "Lelo"

.multiselect-container,
.multiselect,
.date-range-picker
  min-height: $multiselect-height
  width: $multiselect-width
  height: $multiselect-height
  font-family: "Lelo"

.compare-btn
  m-font-size(14, 20)
  padding-top: 0
  padding-bottom: 0
  height: $multiselect-height
  white-space: nowrap
</style>