<template>
  <div class="product-rankings">
    <Section
      :id="'product_rankings'"
      :button-filters="true"
      :all-fillter="true"
      :current-type="currentType"
      @change-type="currentType=$event"
    >
      <div class="card-wrapper" :class="cardContents.length>1?'multi':'single'">
        <Card v-for="card in cardContents" :key="card.type" :title="cardTitle(card.type)">
          <template v-if="card.actions" v-slot:header>
            <button
              v-for="action in card.actions"
              :key="action.id"
              class="btn btn--primary btn-comparison"
              @click="action.handler"
            >
              {{ action.label }}
            </button>
          </template>
          <ol v-if="card.items && card.items.length>0" :class="{'multi-col': cardContents.length===1}" class="rank-list">
            <li v-for="item in card.items" :key="item.id" class="rank-item">
              <BrandDisplay v-if="card.type === 'BRAND'" :data="item" :compact="true" />
              <FragranceDisplay v-if="card.type === 'FRAGRANCE'" :data="item" :compact="true" />
              <FamilyDisplay
                v-if="card.type === 'FAMILY'"
                :data="item"
                :compact="true"
                :size="'md'"
              />
              <IngredientDisplay v-if="card.type === 'INGREDIENT'" :data="item" :compact="true" />
            </li>
          </ol>
          <Loader v-else-if="card.items && card.items.length===0" :text="$t('no_data')" />
          <Loader v-else />
        </Card>
      </div>
    </Section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getTopEntitiesWithVariations } from '../../services/api'
import Section from './Section.vue'
import Card from './Card.vue'
import BrandDisplay from './InsightDisplay/BrandDisplay.vue'
import FragranceDisplay from './InsightDisplay/FragranceDisplay.vue'
import FamilyDisplay from './InsightDisplay/FamilyDisplay.vue'
import IngredientDisplay from './InsightDisplay/IngredientDisplay.vue'
import Loader from '../Loader.vue'
import InView from '@/mixins/InView.js'
import { transformToPreviousPeriod } from '@/utils/filterUtils'
// import { getVariations } from '@/utils/variationUtils'
import { ROUTE_NAMES } from '../../settings/routerSettings'

const TOP_X = 5
export default {
  components: {
    Section,
    Card,
    BrandDisplay,
    FragranceDisplay,
    FamilyDisplay,
    IngredientDisplay,
    Loader,
  },
  mixins: [InView],
  data() {
    return {
      currentType: 'ALL',
    }
  },
  computed: {
    ...mapGetters(['filters', 'isSimplified']),
    isFullUser() {
      return true //VERY FAKE
      // return this.$store.state.user?.data?.role === 'FULL_USER'
      // return false
    },
    cardContents() {
      if (this.currentType === 'ALL') {
        return [
          {
            type: 'BRAND',
            items: this.$asyncComputed.brands.success && this.brands?.slice(0, TOP_X),
            actions: this.isFullUser && !this.isSimplified
              ? [
                  {
                    label: 'Puig comparison',
                    id: 1,
                    handler: () => this.goToCompare('BRAND', this.brands),
                  },
                ]
              : null,
          },
          {
            type: 'FRAGRANCE',
            items: this.$asyncComputed.fragrances.success && this.fragrances?.slice(0, TOP_X),
            actions: this.isFullUser && !this.isSimplified
              ? [
                  {
                    label: 'Puig comparison',
                    id: 1,
                    handler: () => this.goToCompare('FRAGRANCE', this.fragrances),
                  },
                ]
              : null,
          },
          {
            type: 'FAMILY',
            items: this.$asyncComputed.families.success && this.families?.slice(0, TOP_X),
          },
          {
            type: 'INGREDIENT',
            items: this.$asyncComputed.ingredients.success && this.ingredients?.slice(0, TOP_X),
          },
        ]
      } else {
        return [
          {
            type: this.currentType,
            items: this.allOfCurrentType?.slice(0, 4 * TOP_X),
            actions:
              (this.currentType === 'BRAND' || this.currentType === 'FRAGRANCE') && this.isFullUser && !this.isSimplified
                ? [
                    {
                      label: 'Puig comparison',
                      id: 1,
                      handler: () => this.goToCompare(this.currentType, this.allOfCurrentType),
                    },
                  ]
                : null,
          },
        ]
      }
    },
    allOfCurrentType() {
      if (this.currentType === 'BRAND')
        return this.$asyncComputed.brands.success ? this.brands : null
      else if (this.currentType === 'FRAGRANCE')
        return this.$asyncComputed.fragrances.success ? this.fragrances : null
      else if (this.currentType === 'FAMILY')
        return this.$asyncComputed.families.success ? this.families : null
      else if (this.currentType === 'INGREDIENT')
        return this.$asyncComputed.ingredients.success ? this.ingredients : null
      else return []
    },
    previousFilters() {
      return transformToPreviousPeriod(this.filters)
    },
  },
  methods: {
    goToCompare(type, entities) {
      this.$router.push({
        name: ROUTE_NAMES.EntityCompareView,
        query: { type },
        params: { topEntities: entities?.slice(0, 5) },
      })
    },
    cardTitle(type) {
      let word
      if (type === 'BRAND') word = 'brands'
      else if (type === 'FRAGRANCE') word = 'perfumes'
      else if (type === 'FAMILY') word = 'families'
      else if (type === 'INGREDIENT') word = 'ingredients'
      return `Top ${this.cardContents.find(d => d.type === type).items?.length} ${word}`
    },
    fetchRanking(type) {
      return getTopEntitiesWithVariations(type, this.filters, this.previousFilters)
      // return getTopEntities(type, this.filters).then(data => {
      //   return getTopEntities(type, { ...this.previousFilters, id: data.map(d => d.id) }).then(
      //     prevData => {
      //       return data.map((datum, i) => {
      //         // console.log('data/prevData', data, prevData)
      //         const variations = getVariations(
      //           data[i],
      //           prevData[i],
      //           this.filters,
      //           this.previousFilters,
      //           ['vPopIndex']
      //         )
      //         return {
      //           ...data[i],
      //           vsLastPeriod: variations['vPopIndex'].vsLastPeriod,
      //           trend: variations['vPopIndex'].trend,
      //         }
      //       })
      //     }
      //   )
      // })
    },
  },
  asyncComputed: {
    brands() {
      // return this.isInView ? getTopEntities('BRAND', this.filters) : null
      return this.fetchRanking('BRAND')
    },
    fragrances() {
      // return this.isInView ? getTopEntities('FRAGRANCE', this.filters) : null
      return this.fetchRanking('FRAGRANCE')
    },
    families() {
      // return this.isInView ? getTopEntities('FAMILY', this.filters) : null
      return this.fetchRanking('FAMILY')
    },
    ingredients() {
      // return this.isInView ? getTopEntities('INGREDIENT', this.filters) : null
      return this.fetchRanking('INGREDIENT')
    },
  },
}
</script>
<style lang="stylus" scoped>
.product-rankings
  position: relative

  .card-wrapper
    $margin = vw(-10px)
    display: flex
    margin: $margin

    &.single
      .card
        flex: 1 1 0%
        width: 100%

    .card
      min-height: vw(500px)

    >>> .card__header
      height: vw(35px)

  .rank-list
    counter-reset: list
    display: flex
    flex: 1 1 0%
    flex-direction: column
    margin-bottom: 0
    margin-top: 0
    padding: 0

    &.multi-col
      column-count: 4
      display: block
      flex-direction: row
      flex-wrap: wrap

      // width: calc(25% - 20px)
      .rank-item
        border-bottom: 1px solid rgba(#000, 0.1)
        margin-bottom: vw(20px)
        padding-bottom: vw(10px)
        // $margin = vw(10px)
        // $percentage = percentage((1 / 4))
        // $width = "calc(%s - (%s * 2))" % ($percentage $margin)
        // flex: 0 0 $width
        // margin: $margin
        // width: $width

  .rank-item
    display: flex
    // overflow: hidden
    height: vw(80px)

    &:not(:last-child)
      border-bottom: 1px solid rgba(#000, 0.1)
      margin-bottom: vw(20px)
      padding-bottom: vw(10px)

    &:before
      m-font-size(14, 22)
      content: counter(list) "."
      counter-increment: list
      font-family: "Lelo"
      margin-right: vw(10px)
      padding-top: vw(6px)

.btn-comparison
  m-font("Lelo", "light")
  m-font-size(12, 14)
  background-color: #fff
  color: #4F4F4F !important
  padding-bottom: 0.85em
  text-transform: uppercase

  &:hover
    color: #fff !important
</style>