// import { getProductsData } from '../../../services/api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { setUTCHHMMSSToZero } from '../../../utils/filterUtils'

dayjs.extend(utc)
//SETTEAMOS aqui el "from/to" inicial, para que no se pida "2 veces"
export const defaultTopFilters = {
  technology: null,
  gender: null,
  sensor: null,
  country: null,
  from: setUTCHHMMSSToZero(dayjs().utc().subtract(7, 'days').startOf('day').toDate()),
  to: setUTCHHMMSSToZero(dayjs().utc().subtract(1, 'days').endOf('day').toDate()),
}

export default {
  state: {
    topFilters: {
      ...defaultTopFilters,
    },
    subFilters: {
      // gender: [],
      // category: [],
    },
    data: null,
  },
  getters: {
    filters: state => {
      return { ...state.topFilters }
    },
    topFilters: state => {
      return state.topFilters
    },
    subFilters: state => {
      return state.subFilters
    },
    productDataAvailable: state => {
      return !!state.data
    },
    sensorsOverview: state => {
      return state.data && state.data.sensorsOverview['notino-airparfum']
    },
    trendingNow: state => {
      return {
        brand: state.data.brands[0],
        fragrance: state.data.fragrances[0],
        families: state.data.families[0],
        ingredient: state.data.ingredients[0],
        perfumist: state.data.perfumists[0],
      }
    },
    productRankings: state => {
      return {
        brands: state.data.brands,
        fragrances: state.data.fragrances,
        families: state.data.families,
        ingredients: state.data.ingredients,
        perfumists: state.data.perfumists,
      }
    },
  },
  mutations: {
    setFilters(state, filters) {
      state.filters = filters
    },
    setTopFilters(state, topFilters) {
      // if (JSON.stringify(state.topFilters) !== JSON.stringify(topFilters))
      state.topFilters = topFilters
    },
    setSubFilters(state, subFilters) {
      // if (JSON.stringify(state.subFilters) !== JSON.stringify(subFilters))
      state.subFilters = subFilters
    },
    PRODUCT_DATA_REQUEST(state) {
      state.PRODUCT_DATA_REQUEST = true
      state.PRODUCT_DATA_REQUEST_SUCCESS = false
      state.PRODUCT_DATA_REQUEST_FAILURE = false
    },
    PRODUCT_DATA_REQUEST_SUCCESS(state, data) {
      state.PRODUCT_DATA_REQUEST = false
      state.PRODUCT_DATA_REQUEST_SUCCESS = true
      state.data = data
    },
    PRODUCT_DATA_REQUEST_FAILURE(state) {
      state.PRODUCT_DATA_REQUEST = false
      state.PRODUCT_DATA_REQUEST_FAILURE = true
    },
  },
  actions: {
    // async setFilters({ commit }, filters) {
    //   commit('setFilters', filters)
    //   // commit('PRODUCT_DATA_REQUEST')
    //   // let productData
    //   // try {
    //   //   productData = await getProductsData(filters)
    //   // } catch (ex) {
    //   //   commit('PRODUCT_DATA_REQUEST_FAILURE')
    //   // }
    //   // commit('PRODUCT_DATA_REQUEST_SUCCESS', productData)
    // },
    async setTopFilters({ commit }, topFilters) {
      commit('setTopFilters', topFilters)
    },
    async setSubFilters({ commit }, subFilters) {
      commit('setSubFilters', subFilters)
    },
  },
}
