/** @enum */
export const ROUTE_NAMES = {
  Login: 'Login',
  SensorEcosystem: 'SensorEcosystem',
  EntityCompareView: 'EntityCompareView',
  ProductInsights: 'ProductInsights',
  Ingredients: 'Ingredients',
  MainPage: 'MainPage',
  ResetPassword: 'ResetPassword',
  Funnel: 'Funnel',
  TopChosen: 'TopChosen',
}