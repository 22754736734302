<template>
  <div class="sensor-map">
    <dot-map ref="map" :map-state="mapState" @update="updatePoints" />
    <div class="points">
      <div
        v-for="point in mapPoints"
        :key="point.id"
        class="point"
        :style="point.style"
        @mouseenter="onMouseenter(point)"
        @mouseleave="onMouseleave(point)"
      />
    </div>
    <div v-if="tooltipData" class="tooltip" :style="tooltipData.style">
      <div class="label">{{ tooltipData.name }}</div>
      <div class="value">{{ valueGetter(tooltipData) }}<div class="unit">{{ dimension }}</div></div>
    </div>
    <Loader v-if="$asyncComputed.countriesData.updating" />
    <timelapse
      :range="range"
      :enable="dayByDay"
      :loading="$asyncComputed.countriesData.updating"
      @toggle="dayByDay=$event"
      @range="range=$event"
    />
  </div>
</template>

<script>
import DotMap from './DotMap.vue'
import { scalePow as d3_scalePow } from 'd3-scale'
import { extent as d3_extent } from 'd3-array'
import country_centers from '@/assets/geojson/countries_centers'
import { getTopEntities, getCountries, getEvolutive } from '@/services/api'
import Loader from './Loader.vue'
import { getSensors } from '../services/api'
import GetFilterBySetting from '../mixins/GetFilterBySetting'
import FilteredSensor from '../mixins/FilteredSensor'
import Timelapse from './Timelapse.vue'
import IndicatorMixin from '../mixins/IndicatorMixin'
export default {
  components: { DotMap, Loader, Timelapse },
  mixins: [GetFilterBySetting, FilteredSensor, IndicatorMixin],
  props: ['dimension'],
  data() {
    return {
      mapPoints: [],
      mapState: { scale: 250, centerLat: 20, centerLon: 0 },
      selection: null,
      range: 0,
      dayByDay: false,
      playing: false,
    }
  },
  computed: {
    //...mapGetters(['topFilters']),
    tooltipData() {
      if (!this.selection) return null
      const n = this.numberOfSensorsWithCountry(this.selection)
      return {
        ...this.selection,
        name: `${this.selection.name}` + (n > 0 ? ` (${n} sensors)` : ''),
        style: {
          top: this.selection.style.top,
          left: this.selection.style.left,
        },
      }
    },
  },
  watch: {
    countriesData() {
      this.$refs.map.redraw()
    },
    size() {
      this.$refs.map.redraw()
    },
    dimension() {
      this.$refs.map.redraw()
    },
  },
  // computed: {
  //   points() {
  //     return country_centers.map(cp => {
  //       return {
  //         id: cp.id,
  //         ISO2: cp.short_name,
  //         label: cp.long_name,
  //         coords: [cp.center_lng, cp.center_lat],
  //       }
  //     })
  //   },
  // },
  methods: {
    onMouseenter(point) {
      this.selection = point
    },
    onMouseleave(point) {
      if (this.selection === point) this.selection = null
    },
    valueGetter(d) {
      return d.values[this.dimension]
    },
    updatePoints(projection) {
      if (!this.countriesData) return []
      let dotScale = d3_scalePow()
        .exponent(0.5)
        .range([2, 20])
        .domain(d3_extent(this.countriesData, this.valueGetter))
      this.mapPoints = this.countriesData.map(p => {
        let pos = projection(p.coords)
        let radius = dotScale(this.valueGetter(p))
        return {
          ...p,
          style: {
            left: pos[0] + 'px',
            top: pos[1] + 'px',
            width: 2 * radius + 'px',
            height: 2 * radius + 'px',
          },
        }
      })
    },
    numberOfSensorsWithCountry(country) {
      return this.availableSensors.filter(s => s.countries?.includes(country.id)).length
    },
  },
  asyncComputed: {
    availableSensors() {
      return getSensors(this.topFilters)
    },
    async countriesData() {
      // WARNING! Before await we lost reactivityO.o
      if (!this.topFilters.from) return
      const playingDate = new Date(
        Date.UTC(
          this.topFilters.from.getFullYear(),
          this.topFilters.from.getMonth(),
          this.topFilters.from.getDate()
        )
      )
      playingDate.setDate(playingDate.getDate() + Number(this.range))
      const timePlayingDate = playingDate.getTime()
      const fetchEvolutive = this.dayByDay
      const filteredSensor = this.filteredSensor
      const sessionKpis = this.sessionKpis
      if (!this.topFilters.from || !this.topFilters.to || sessionKpis.length === 0) return null
      const resp = await getCountries(this.topFilters)
      let countries = resp
      var response = null
      if (fetchEvolutive) {
        const evolResponse = await getEvolutive(
          'SESSION',
          'SESSION',
          { ...this.topFilters, country: null, sensor: filteredSensor },
          sessionKpis,
          { aggregations: ['country'] }
        )
        response = evolResponse.filter(
          session => new Date(session.date).getTime() === timePlayingDate
        )
      } else
        response = await getTopEntities(
          'SESSION',
          { ...this.topFilters, country: null, sensor: filteredSensor },
          sessionKpis,
          { aggregations: ['country'] }
        )

      // return Promise.all(promises).then(datas => {
      // console.log('datas', datas)
      let datas = response
      countries.forEach(s => {
        s.values = datas.find(d => d.id.country === s.id)
        let candidate = country_centers.find(cc => cc.short_name === s.id)
        if (candidate) {
          s.coords = [candidate.center_lng, candidate.center_lat]
        }
      })
      return countries.filter(
        c =>
          c.coords &&
          c.values &&
          (!this.topFilters.country ||
            this.topFilters.country.length === 0 ||
            this.topFilters.country.includes(c?.id))
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.sensor-map
  width: 100%
  height: 100%

.points
  position: absolute
  top: 0
  left: 0

  .point
    position: absolute
    width: 10px
    height: 10px
    border-radius: 100%
    background: $blue
    cursor: pointer
    transition: width 0.5s, height 0.5s
    transform: translate(-50%, -50%)

.tooltip
  position: absolute
  display: flex
  flex-direction: column
  padding: 5px
  min-width: 120px

  .label
    m-font-size(20)

  .value
    m-font-size(28)
    display: flex
    align-items: baseline

    .unit
      m-font-size(18)
      margin-left: 4px
</style>