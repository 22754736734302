<template>
  <div class="sensors-overview">
    <div class="sensors-overview__title">Sensor Overview</div>
    <Loader v-if="!sessionKpis.length || $asyncComputed.data.updating" />
    <div v-else-if="data && data.length>0" class="sensors-overview__indicators">
      <IndicatorCard
        v-for="indicator in data"
        :key="indicator.id"
        :type="indicator.id"
        :compact="true"
        :label="indicator.label"
        :value="indicator.value"
        :vs-last-period="indicator.vsLastPeriod"
        :trend="indicator.trend"
        @showEvol="()=>showEvol(indicator.id)"
      />
    </div>
    <Loader v-else :text="$t('no_data')" />
  </div>
</template>

<script>
// const INDICATORS = [
//   {
//     label: '(F) SESSIONS',
//     id: 'vPopIndex',
//   },
//   {
//     label: '(F) DURATION',
//     id: 'vRecommended',
//   },
//   {
//     label: '(F) FRAGRANCES TESTED',
//     id: 'vInteraction',
//   },
// ]

import { transformToPreviousPeriod } from '@/utils/filterUtils'
// import { getVariations } from '@/utils/variationUtils'
import { mapGetters } from 'vuex'
import IndicatorCard from './IndicatorCard.vue'
// import { getSensorsOverview } from '@/services/api'
import Loader from '../Loader.vue'
import { getTopEntitiesWithVariations } from '../../services/api'
import IndicatorMixin from '../../mixins/IndicatorMixin'

/** task #341: Remove vBounceRate from sessionKpis */
const HIDDEN_KPIS = ['vBounceRate']
export default {
  components: { IndicatorCard, Loader },
  mixins: [IndicatorMixin],
  computed: {
    ...mapGetters(['topFilters']),
  },
  asyncComputed: {
    data() {
      const keys = Object.keys(this.indLabels)
      const sort = (a, b) => {
        const ia = keys.indexOf(a.id)
        const ib = keys.indexOf(b.id)
        if (ia < 0) return -1
        if (ib < 0) return 0
        return ia - ib
      }
      let currentFilters = this.topFilters
      let prevFilters = transformToPreviousPeriod(this.topFilters)
      //  task #341: Remove vBounceRate from sessionKpis
      const kpis = this.sessionKpis.filter(kpi=>!HIDDEN_KPIS.includes(kpi))
      return (
        this.sessionKpis.length &&
        getTopEntitiesWithVariations('SESSION', currentFilters, prevFilters, kpis).then(
          entities => {
            if (entities.length === 0) return []
            return kpis
              .map(kpi => {
                return {
                  id: kpi,
                  label: this.indLabels[kpi],
                  value: entities[0][kpi],
                  vsLastPeriod: entities[0].variations[kpi].vsLastPeriod,
                  trend: entities[0].variations[kpi].trend,
                }
              })
              .sort(sort)
          }
        )
      )
      // return Promise.all([
      //   getTopEntities('SESSION', currentFilters),
      //   getTopEntities('SESSION', prevFilters),
      //   // getEvolutive('SESSION', 'SESSION', this.topFilters),
      // ]).then(resp => {
      //   let finals = resp[0]
      //   let lastPeriod = resp[1]
      //   let evols = resp[2] ?? []
      //   const keys = Object.keys(this.indLabels)
      //   const sort = (a, b) => {
      //     const ia = keys.indexOf(a.id)
      //     const ib = keys.indexOf(b.id)
      //     if (ia < 0) return -1
      //     if (ib < 0) return 0
      //     return ia - ib
      //   }
      //   let current = finals[0]
      //   let previous = lastPeriod[0]
      //   let variations = getVariations(
      //     current,
      //     previous,
      //     currentFilters,
      //     prevFilters,
      //     this.sessionKpis
      //   )
      //   return this.sessionKpis
      //     .map(kpi => {
      //       const value = current[kpi]
      //       const vsLastPeriod = variations[kpi].vsLastPeriod
      //       const trend = variations[kpi].trend
      //       return {
      //         id: kpi,
      //         label: this.indLabels[kpi],
      //         evolutive: evols.map(d => {
      //           return {
      //             date: d.date,
      //             value: d[kpi],
      //           }
      //         }),
      //         value,
      //         vsLastPeriod,
      //         trend,
      //       }
      //     })
      //     .filter(kpi => !Number.isNaN(Number(kpi.value)))
      //     .sort(sort)
      // })
    },
  },
  methods: {
    showEvol(kpi) {
      this.$modal.show('evolutive', {
        evolConfig: {
          kpi: kpi,
          type: 'SESSION',
          filters: this.topFilters,
          id: null,
          values: this.sessionKpis,
        },
        panelConfig: {
          variations: {
            vsLastPeriod: this.data.find(d => d.id === kpi).vsLastPeriod,
            trend: this.data.find(d => d.id === kpi).trend,
          },
        },
      })
    },
  },
}
</script>

<style lang="stylus">
.sensors-overview
  z-index: 2
  margin-bottom: vw(50px)
  min-height: vw(100px)
  width: 100%

.sensors-overview__indicators
  display: flex
  gap: vw(20px)
  -ms-overflow-style: none /* for Internet Explorer, Edge */
  scrollbar-width: none /* for Firefox */

  &::-webkit-scrollbar /* Safari and Chrome */
    display: none

  .indicator-card
    flex: 1 1 0%

.sensors-overview__title
  m-font-size(18, 25)
  margin-bottom: vw(5px)

.variation-indicator
  font-weight: 400
  font-size: 14px
</style>